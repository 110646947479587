function validate( parent ) {

    var generalError = false;

    jQuery(parent + ' input.validate' ).each(function(){

        var error = false;

        var emailRegex = new RegExp(/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/i);

        var type = jQuery(this).attr('data-validate');
        var value = jQuery(this).val();

        if ( jQuery(this).hasClass('required') ) {

            if ( !value || value == '' || value == ' ' ) {
                error = true;
            }

        }

        switch (type) {

            case 'number':
                if ( ! jQuery.isNumeric( value ) ) {
                    error = true;
                }
                break;

            case 'email':
                if ( ! emailRegex.test( value ) ) {
                    error = true;
                }
                break;

        }

        if ( error == true ) {
            generalError = true;
            jQuery(this).css( 'border-color', '#e41919' );
        }

    });

    jQuery(parent + ' textarea.validate' ).each(function(){

        var error = false;

        var value = jQuery(this).val();

        if ( jQuery(this).hasClass('required') ) {

            if ( !value || value == '' || value == ' ' ) {
                error = true;
            }

        }

        if ( error == true ) {
            generalError = true;
            jQuery(this).css( 'border-color', '#e41919' );
        }

    });

    if ( generalError == true ) {
        return false;
    }

    return true;

}

$(document).ready(function(){

    $("#sms_download").on( 'click', '#sms_download_submit', function(){

        if ( !validate( '#sms_download' ) ) {
            return false;
        }

        $('#sms_download .element-loader').fadeIn();

        post_data = {
            'phone': $("#sms_download_phone_number").val(),
            '_token': $("#global_token").val()
        };

        $.post( '/ajax/sms-download-links', post_data, function(response){

            $('#sms_download .element-loader').fadeOut();

            var output = '';

            for ( var i = 0; i < response.length; i++ ) {

                output += response[i];

            }

            $("#sms_download_result").hide().html(output).slideDown();

            $("#sms_download_phone_number").val('');

        });

        return false;

    });

    $("#request_city").on( 'click', '#request_city_submit', function(){

        if ( !validate( '#request_city' ) ) {
            return false;
        }

        $('#request_city .element-loader').fadeIn();

        post_data = {
            'name': $("#request_city_name").val(),
            'email': $("#request_city_email").val(),
            '_token': $("#global_token").val()
        };

        $.post( '/ajax/request-city', post_data, function(response){

            $('#request_city .element-loader').fadeOut();

            var output = '';

            for ( var i = 0; i < response.length; i++ ) {

                output += response[i];

            }

            $("#request_city_result").hide().html(output).slideDown();

            $("#request_city_name").val('');
            $("#request_city_email").val('');

        });

        return false;

    });

    $("#newsletter_subscribe").on( 'click', '#newsletter_submit', function(){

        if ( !validate( '#newsletter_subscribe' ) ) {
            return false;
        }

        $('#newsletter_subscribe .element-loader').fadeIn();

        post_data = {
            'name': $("#newsletter_name").val(),
            'email': $("#newsletter_email").val(),
            '_token': $("#global_token").val()
        };

        $.post( '/ajax/subscribe', post_data, function(response){

            $('#newsletter_subscribe .element-loader').fadeOut();

            var output = '';

            for ( var i = 0; i < response.length; i++ ) {

                output += response[i];

            }

            $("#newsletter_result").hide().html(output).slideDown();

            $("#newsletter_name").val('');
            $("#newsletter_email").val('');

        });

        return false;

    });

    $("#contact_forma").on( 'click', '#submit_btn', function(){

        if ( !validate( '#contact_form' ) ) {
            return false;
        }

        $('#contact_form .element-loader').fadeIn();

        post_data = {
            'name': $("#contact_name").val(),
            'email': $("#contact_email").val(),
            'message': $("#contact_message").val(),
            '_token': $("#global_token").val()
        };

        $.post( '/ajax/contact', post_data, function(response){

            $('#contact_form .element-loader').fadeOut();

            var output = '';

            for ( var i = 0; i < response.length; i++ ) {

                output += response[i];

            }

            $("#contact_result").hide().html(output).slideDown();

            $("#contact_name").val('');
            $("#contact_email").val('');
            $("#contact_message").val('');

        });

        return false;

    });

    //reset previously set border colors and hide all message on .keyup()
    $("#contact_form input, #contact_form textarea").keyup(function(){
        $("#contact_form input, #contact_form textarea").css('border-color', '');
        $("#result").slideUp();
    });

    $("#newsletter_subscribe input").keyup(function(){
        $("#newsletter_subscribe input").css('border-color', '');
        $("#newsletter_result").slideUp();
        $("#newsletter_result").html('');
    });

    $("#request_city input").keyup(function(){
        $("#request_city input").css('border-color', '');
        $("#request_city_result").slideUp();
        $("#request_city_result").html('');
    });

    $("#sms_download input").keyup(function(){
        $("#sms_download input").css('border-color', '');
        $("#sms_download_result").slideUp();
        $("#sms_download_result").html('');
    });

});
