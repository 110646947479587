
'use strict';

window.calcPopUp = function () {

	var ww = window.innerWidth;

	var wh = window.innerHeight;

	var w = 800;

	var h = 800;

	var left = ( ww - w ) / 2;

	var top = ( wh - h ) / 2;

	return 'width=' + w + ', height=' + h + ', left=' + left + ', top=' + top;

};

window.socialShareWindowAttr = calcPopUp();

window.childSocialShareWindow = null;

window.openSocialShareWindow = function ( url ) {

    window.childSocialShareWindow = window.open( url, 'Share', window.socialShareWindowAttr );

};

window.facebookShare = function ( url ) {

    var dest = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI( url );

    openSocialShareWindow( dest );

};

window.twitterShare = function ( url ) {

    var dest = 'https://twitter.com/intent/tweet?url=' + encodeURI( url ) + '&via=UKiProductions';

    openSocialShareWindow( dest );

};

window.linkedinShare = function ( url ) {

    var dest = 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURI( url ) + '&source=UKiProductions';

    openSocialShareWindow( dest );

};

window.pinterestShare = function ( url, src ) {

    var dest = 'http://pinterest.com/pin/create/button/?url=' + encodeURI( url ) + '&media=' + encodeURI( src );

    openSocialShareWindow( dest );

};

window.googleShare = function ( url ) {

    var dest = 'https://plus.google.com/share?url=' + encodeURI( url );

    openSocialShareWindow( dest );

};

window.shareMarkup = function ( url, src ) {

	return '<div class="soc-share" data-url="' + url + '" data-src="' + src + '">\
    <a style="color: #3B5998;" href="#" class="ss-facebook" title="Share"><i class="fa fa-facebook"></i></a>\
    <a style="color: #17B8EC;" href="#" class="ss-twitter" title="Tweet"><i class="fa fa-twitter"></i></a>\
    <a style="color: #db4437;" href="#" class="ss-google" title="Share"><i class="fa fa-google-plus"></i></a>\
	<a style="color: #0084bf;" href="#" class="ss-linkedin" title="Post"><i class="fa fa-linkedin"></i></a>\
	<a style="color: #bd081c;" href="#" class="ss-pinterest" title="Pin It"><i class="fa fa-pinterest"></i></a>\
	</div>';

};

jQuery( window ).load( function () {

	jQuery( '.social-share-w' ).each( function () {

		var lUrl = jQuery( this ).attr( 'data-append-url' );

		var lSrc = lUrl;

		if ( jQuery( this ).attr( 'data-append-src' ) ) {

			lSrc = jQuery( this ).attr( 'data-append-src' );

		}

		jQuery( this ).html( shareMarkup( lUrl, lSrc ) );

	} );

	jQuery( 'body' ).on( 'click', '.soc-share a', function () {

		var par = jQuery( this ).parent();

		var u = par.attr( 'data-url' );

		var src = par.attr( 'data-src' );

		var platform = jQuery( this ).attr( 'class' );

		var top = par.parent().parent();

		if ( top.hasClass( 'slider-has-ss' ) ) {

			var i = top.find( '.owl-pagination .active' ).index();

			var current = top.find( '.owl-wrapper .owl-item:eq( ' + i + ' ) li img' );

			if ( current ) {

				u = current.attr( 'src' );

				src = u;

			}

		}

		switch ( platform ) {

			case 'ss-facebook' :

				facebookShare( u );

				break;

			case 'ss-twitter' :

				twitterShare( u );

				break;

			case 'ss-google' :

				googleShare( u );

				break;

			case 'ss-linkedin' :

				linkedinShare( u );

				break;

			case 'ss-pinterest' :

				pinterestShare( u, src );

				break;

			default:

				break;

		}

		return false;

	} );

} );


